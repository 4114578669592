<template>
  <div class="box">
    <!-- 顶部图片 -->
    <!-- <div class="topBg">
          <div class="integral">
            我的积分:<span style="font-weight: 700">{{ integralNum }}</span
            >分
          </div>
        </div> -->
    <!-- tabs切换 -->
    <!-- <van-tabs :active="type" sticky @change="CateIdClick">
          <van-tab
            v-for="item in tabList"
            :key="item.STypeId"
            :title="item.Name"
            :name="item.STypeId"
            color="#ff4500"
            background="#ff4500"
          >
          </van-tab>
        </van-tabs> -->
    <!-- 列表 -->
    <!-- 没有更多了 -->
    <van-empty v-if="!list.length" description="─── 暂无服务列表 ───" />
    <div class="lists" v-else>
      <div class="list" v-for="item in list" :key="item.id">
        <!-- @click="gotoUrl('/huayang/home/' + item.RoomId)" -->
        <div style="display: flex; border-bottom: 1px solid #f4f4f4">
          <div class="listImg">
            <img
              v-if="item.Logo"
              :src="item.Logo"
              style="width: 100%; height: 100%; border-radius: 2px"
            />
            <span v-else>暂无</span>
          </div>
          <div class="listTitle">
            <span>{{ item.Name }}</span>
            <div class="listLabel">
              <div style="display: flex">
                <div style="width: 50%">
                  <img
                    src="https://dximg.dexian.ren/time.png"
                    alt=""
                    style="width: 9px; height: 9px"
                  />
                  {{ item.WorkTime }}
                </div>
                <div>
                  <img
                    src="https://dximg.dexian.ren/tel.png"
                    alt=""
                    style="width: 9px; height: 9px"
                  />
                  {{ item.TouchTel }}
                </div>
              </div>
              <div>
                <img
                  src="https://dximg.dexian.ren/adress1.png"
                  alt=""
                  style="width: 9px; height: 9px"
                />
                {{ item.Address }}
              </div>
            </div>
          </div>
        </div>
        <van-button class="btn" @click="preBook(item.RoomId)"
          ><img
            class="prebook"
            src="https://dximg.dexian.ren/perbook.png"
          />预约</van-button
        >
        <!-- <span @click="callPhone(item.TouchTel)"
            ><van-button class="btn btnFn">
              <img
                class="prebook"
                src="https://dximg.dexian.ren/telFn.png"
              />拨号</van-button
            ></span
          > -->
      </div>
    </div>
    <!-- 购物车 -->
    <!-- <div class="shopping" @click="shoppingClick()" v-if="shoppongShow">
          <img
            src="../../assets/shop/shopping.png"
            alt=""
            style="width: 60%; margin: 12px 17%"
          />
        </div> -->
    <div>
      <van-popup
        v-model="shoppingShow"
        round
        position="bottom"
        :style="{ height: '70%' }"
      >
        <!-- 商品列表 -->
        <div class="goodsLists">
          <div
            class="goodsList"
            v-for="item in shoppingList"
            :key="item.GoodsId"
          >
            <div style="width: 32%">
              <img
                :src="item.GoodsImg"
                style="width: 110px; height: 100px; margin: 10px"
              />
            </div>
            <div style="width: 65%">
              <div class="flex">
                <span style="color: #01a0fb; font-weight: 700">{{
                  item.GoodsName
                }}</span>
                <span>{{ item.Price }}/{{ item.MUnit }}</span>
              </div>
              <div class="flex">
                <span style="color: #ee0c24">¥{{ item.Amount }}</span>
                <span>
                  <van-stepper
                    v-model="item.GoodsNum"
                    theme="round"
                    min="0"
                    button-size="22"
                    disable-input
                    @change="Goodschange(item)"
                    style="display: inline-block"
                  />
                </span>
              </div>
              <div class="flex">
                <button @click="goodsDelete(item.OGoodsId)" class="delete">
                  删除
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- 结算按钮 -->
        <div class="button">
          <button @click="orderClick()">结 算</button>
        </div>
      </van-popup>
    </div>
    <!-- 收货地址 -->
    <van-popup
      @close="close"
      v-model="addressShow"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div>
        <van-cell-group>
          <van-field
            v-model="datafrom.PreTime"
            required
            @click="idListOrg = true"
            readonly="readonly"
            label="预约时间"
            placeholder="请选择预约时间"
          />
          <van-popup v-model="idListOrg" position="bottom" get-container="body">
            <van-datetime-picker
              @confirm="onidListOrg"
              @cancel="idListOrg = false"
              v-model="datafrom.PreTime"
              type="datetime"
              :columns-order="['year', 'month', 'day', 'hour', 'minute']"
              :formatter="formatter"
              title="请选择看房时间"
              :min-date="minDate"
              :max-date="maxDate"
              format="yyyy-MM-dd "
            />
          </van-popup>
        </van-cell-group>
        <van-field
          v-model="datafrom.Content"
          rows="3"
          autosize
          label="内容"
          type="textarea"
          placeholder="请输入服务内容"
          style="margin-top: 15px"
        />
        <div
          style="
            position: absolute;
            width: 100%;
            bottom: 12px;
            text-align: center;
          "
        >
          <van-button class="button" @click="PlaceOrder">确定</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
    <script>
import { dateFormat } from "@/utils/moment";
import {
  WxGetShopPage,
  WxGetGoodsSortList,
  WxGetShopTypeList,
  WeMyMemberInfo,
  WxGetFnRoomPage,
} from "@/api/RealInfo";
import {
  WeGenerateOrder,
  WeGetMyOrderPage,
  WeGetOrderGoodsList,
  WxPreApply,
  WeDeleteOrderGoods,
  WeSaveOrderService,
  WeGetMyBindInfoPage,
} from "@/api/shop";
import { getOpenId, setOpenId } from "@/utils/auth";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
Vue.use(Toast);
Vue.use(Dialog);
export default {
  data() {
    return {
      idListOrg: false,
      datafrom: {
        OpenID: getOpenId(),
        RoomId: "",
        PreTime: "",
        Content: "",
      },
      infoId: this.$route.params.id,
      shopType: "", //商铺类型
      type: 29, //tabs选中
      tabList: [], //tabs类型
      list: [], //商品列表
      integralNum: 0,
      shoppingList: [], //购物车列表
      shoppingShow: false, //购物车弹窗
      order: "", //订单
      addressShow: false, //收货地址
      shoppongShow: false, //购物车按钮
      addressList: [], //收货地址
      checked: "0", //收货地址选择
      integralCk: "1", //积分选择
      CurIntegral: "", //会员当前积分
      PmCurIntegral: "", //党员当前积分
      VolCurIntegral: "", //志愿者当前积分（时间币）
      totalNum: 0, //总商品数
      totalGold: 0, //总金额
      minDate: new Date(),
      maxDate: new Date(2038, 12, 30),
    };
  },
  mounted() {
    // setOpenId("oas2d4qNkpHqYBS0NlA4glPsVR1k"); //测试openid
    this.getTabsList();
    this.getList();
    this.getMemberByOpenID();
    // this.getUserOrder();
  },
  methods: {
    close() {
      this.datafrom = {
        OpenID: getOpenId(),
        RoomId: "",
        PreTime: "",
        Content: "",
      };
    },
    // 社区选择器
    onidListOrg(val) {
      console.log(dateFormat(val), "valvalvalvalval");
      this.datafrom.PreTime = dateFormat(val);
      this.idListOrg = false;
    },
    preBook(RoomId) {
      console.log(RoomId);
      this.datafrom.RoomId = RoomId;
      this.addressShow = true;
    },
    callPhone(phone) {
      window.location.href = "tel:" + phone;
      //   window.location.href = "http://tel:" + phone + "#mp.weixin.qq.com";
    },
    // 选择商品
    orderClick() {
      this.addressShow = true;
      WeGetMyBindInfoPage({ openID: getOpenId() }).then((res) => {
        if (res.data.count == 0) {
          Dialog.confirm({
            title: "提醒",
            message: "您还没有添加收货地址,是否前往添加!",
          })
            .then(() => {
              if (window.__wxjs_environment === "miniprogram") {
                window.wx.miniProgram.navigateTo({
                  url: "../my/user/oldManList/index?name='收货地址'",
                });
              } else {
                alert("请在小程序中打开");
              }
            })
            .catch(() => {
              return false;
            });
        } else {
          this.addressList = res.data.data;
        }
      });
    },
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "点";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    // 步进器选择
    Goodschange(row) {
      WeSaveOrderService({
        //添加订单商品
        OpenID: getOpenId(),
        OrderId: this.order,
        GoodsId: row.GoodsId,
        GoodsNum: row.GoodsNum,
      }).then((res) => {
        Toast(row.GoodsName + ":" + row.GoodsNum);
        this.getshoppingList(this.order);
      });
    },
    // 获取订单情况
    getUserOrder() {
      console.log(this.list);
      return;
      WeGetMyOrderPage({ RoomId: 74, openID: getOpenId(), status: 1 }).then(
        (res) => {
          if (res.data.code != 0) {
            Toast.fail(res.data.msg);
            this.shoppongShow = false;
          } else {
            if (res.data.count == 0) {
              // 没有订单 生成订单
              WeGenerateOrder({ RoomId: 74, OpenID: getOpenId() }).then(
                (res) => {
                  this.getshoppingList(res.data.data);
                  this.order = res.data.data;
                  this.shoppongShow = true;
                }
              );
            } else {
              // 有订单使用现有订单
              this.getshoppingList(res.data.data[0].OrderId);
              this.order = res.data.data[0].OrderId;
              this.shoppongShow = true;
            }
          }
        }
      );
    },
    // 获取购物车列表
    getshoppingList(row) {
      this.totalNum = 0;
      this.totalGold = 0;
      WeGetOrderGoodsList({ openID: getOpenId(), orderId: row }).then((res) => {
        this.shoppingList = res.data.data;
        for (let i = 0; i < this.shoppingList.length; i++) {
          this.totalNum += this.shoppingList[i].GoodsNum;
          this.totalGold += this.shoppingList[i].Amount;
        }
      });
    },
    // 购物车点击
    shoppingClick() {
      this.shoppingShow = true;
      this.getshoppingList(this.order);
    },
    // 切换分类
    // CateIdClick(row) {
    //   console.log(row);
    //   this.type = row;
    //   this.getList();
    // },
    // 获取不同分类列表
    getTabsList() {
      console.log(3243454);
      WxGetShopTypeList({ shopCate: 2 }).then((res) => {
        this.tabList = res.data.data;
        console.log(this.tabList, ":this.tabList ");
      });
    },
    // 获取列表
    getList() {
      var cateId = this.type;
      WxGetFnRoomPage({ neInfoId: this.infoId }).then((res) => {
        console.log(res, 11111111111);
        this.list = res.data.data;
        // console.log("this", this.list);
        // this.shopType = this.list[0].STypeId;
        // console.log(this.shopType, "this.shopType ");
      });
    },
    // 获取会员信息
    getMemberByOpenID() {
      WeMyMemberInfo({ openID: getOpenId() }).then((res) => {
        if (res.data.code == 0) {
          var data = res.data.data;
          console.log(data, "积分");
          this.integralNum = data.CurIntegral;
          this.CurIntegral = data.CurIntegral;
          this.PmCurIntegral = data.PmCurIntegral;
          this.VolCurIntegral = data.VolCurIntegral;
        } else {
          Toast.fail("您还不是会员,请先注册会员!");
        }
      });
    },
    // 确定
    PlaceOrder() {
      if (this.datafrom.PreTime == "") {
        Toast.fail("请选择预约时间");
        return false;
      }
      WxPreApply(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Toast.success("提交成功!");
          this.addressShow = false;
          //   this.shoppingShow = false;
          this.getMemberByOpenID();
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 删除单独商品
    goodsDelete(row) {
      WeDeleteOrderGoods({
        OpenID: getOpenId(),
        OrderId: this.order,
        OGoodsId: row,
      }).then((res) => {
        if (res.data.code == 0) {
          this.getshoppingList(this.order);
          this.shoppingShow = false;
          Toast.success("删除成功!");
        }
      });
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>
    <style scoped>
.box {
  background: #f7f8fa;
  /* height: 100%; */
  min-height: 100vh;
}

/* 背景 */
.topBg {
  position: relative;
  height: 215px;
  width: 100%;
  background: url("../../assets/jfsc.png") no-repeat center;
  background-size: 100% 100%;
}

.topBg .integral {
  position: absolute;
  right: 0px;
  top: 10px;
  background: #fff;
  padding: 10px 22px;
  font-size: 12px;
  color: #e74d18;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

/* 列表 */
.lists {
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}

.lists .list {
  width: 92%;
  margin-left: 3.5%;
  background: #fff;
  border-radius: 8px;
  margin-top: 10px;
}

.lists .listImg {
  width: 20.3%;
  height: 70px;
  text-align: center;
  margin: 10px 3%;
}

.listTitle {
  flex: 1;
  margin: 10px 1%;
}

.listTitle span:nth-child(1) {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #232325;
}

.listLabel div:nth-child(1) {
  margin-bottom: 1px;
}

.listLabel {
  margin-top: 10px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
/* 购物车 */
.shopping {
  position: fixed;
  right: 15px;
  bottom: 25px;
  width: 65px;
  height: 65px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px #f5eeee;
}
.goodsLists {
  width: 95%;
  margin: 10px auto 10px;
  background: #fff;
  height: 57vh;
  border-radius: 10px;
  overflow-y: scroll;
}
.goodsList {
  display: flex;
  justify-items: center;
  /* align-items: center; */
  background: #f7f8fa;
  border-radius: 10px;
  margin-bottom: 7px;
}
.button {
  width: 100%;
  text-align: center;
}
.flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.button button {
  position: fixed;
  bottom: 15px;
  width: 80%;
  height: 40px;
  line-height: 40px;
  background: #01a0fb;
  color: #fff;
  border: none;
  margin: 8px auto 15px;
  border-radius: 6px;
  left: 10%;
}
.goodsList .delete {
  background: #ee0c24;
  border: none;
  color: #fff;
  font-size: 14px;
  border-radius: 7px;
  padding: 5px 13px;
}
.address .van-radio {
  background: #f5f5f5;
  width: 80%;
  margin: 10px auto;
  padding: 6px 15px;
  border-radius: 6px;
}
.address .radioBox {
  display: flex;
  justify-content: space-between;
  width: 70vw;
  font-size: 14px;
}
.integralBox {
  position: absolute;
  bottom: 81px;
  font-size: 12px;
  margin-left: 10%;
}

.btn {
  width: 70px;
  height: 28px;
  background: #fc4839;
  border: 1px solid #fc4839;
  border-radius: 14px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 8px 13px;
  float: right;
}
.btnFn {
  margin-right: 11px;
  background-color: #fff;
  color: #fc4839;
}
.prebook {
  width: 10.5px;
  vertical-align: middle;
  margin-right: 3px;
}

.button {
  width: 90%;
  background: linear-gradient(to right, #f28c76, #fc4839);
  color: #fff;
  line-height: 48px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
}
</style>